import { SidebarPrefix } from "~/types/general";
import { ServiceInventoryItem } from "~/types/inventory";
import { CustomerShort, CustomerPlanManagement } from "~/types/customers";
import { Order } from "~/types/orders";

interface File {
  lastModified?: number;
  name?: string;
  size?: number;
  type?: string;
  webkitRelativePath?: string;
}

export interface Agree {
  media: File;
  first_name?: string;
  last_name?: string;
  is_agree?: boolean;
}

export interface Auth {
  type: string;
  phone: string;
  mobile: string;
  firstname: string;
  lastname: string;
  contact_preference: string;
  contact_time: string;
  how_hear: string;
  type_select: string;
}

export interface BookingPayload {
  service_inventory_id: number;
  type: BookingServiceType;
  duration: number;
  booked_on: string;
}

export interface BookingHelper {
  store?: {
    address?: string;
    city?: string;
    country?: {
      id?: string;
      name?: string;
    };
    formatted_address?: string;
    lat?: number;
    lng?: number;
    name?: string;
    postalcode?: string;
    province?: {
      code?: string;
      name?: string;
    };
  };
  service_id?: number;
  plan_management?: CustomerPlanManagement;
  types?: BookingServiceType;
  age_ranges?: BookingAgeRange[];
  funding_line?: number | null;
  funding_type?: number | string | null;
  availability_on?: string;
}

export interface BookingStep {
  id: number;
  title: string;
  subtitle: string;
  name: string;
  is_done: boolean;
}

export interface BookingSection {
  id?: number;
  name: string;
  label: string;
  queue?: number;
  type: string;
  is_active: boolean;
  is_protected?: boolean;
  description: string;
  notice: string;
  modules: string[];
  is_done?: boolean;
  use_signature_module?: boolean;
}

export interface BookingResponse {
  id: number;
  customer: CustomerShort;
  booked_on: string;
  duration: number;
  type: BookingServiceType;
  service_inventory: ServiceInventoryItem;
  order: Order;
  created_at: string;
}

export enum BookingServiceType {
  IN_CLINIC = "in-clinic",
  VIRTUAL = "virtual",
  MOBILE = "mobile",
}
export enum BookingFundingType {
  NDIS = "ndis",
  HCP = "hcp",
  CHSP = "chsp",
}

export enum BookingSectionLink {
  START = "start",
  DATE = "commencement-date",
  FUNDING = "funding",
  LOCATION = "location",
  SERVICE = "service",
}

export interface BookingSectionCreatePayload {
  name: string;
  label: string;
  type: string;
  is_active: boolean;
  description?: string;
  notice?: string;
  queue?: number;
  modules?: string[];
}

export enum BookingAgeRange {
  AGE_RANGE_ANY = "any",
  AGE_RANGE_0_6 = "0-6",
  AGE_RANGE_7_13 = "7-13",
  AGE_RANGE_14_17 = "14-17",
  AGE_RANGE_18_PLUS = "18+",
}

export const SidebarPage = {
  BOOKING_SEARCH_FILTERS: `${SidebarPrefix.BOOKING}-search-filters`,
};
