import { defineStore } from "pinia";
import {
  BookingServiceType,
  BookingAgeRange,
  BookingHelper,
} from "~/types/booking";
import { StoreSingle } from "~/types/stores";
import { useUsersStore } from "./users";
import { ServiceInventoryItem } from "~/types/inventory";
import { Meta, Option } from "~/types/general";

export const useBookingStore = defineStore("booking", () => {
  // Sidebar and Location
  const isOpenSidebar = ref(false);
  const isEditCurrentLocation = ref(false);
  const locations = ref<StoreSingle[]>([]);
  const locationsMeta = ref({} as Meta);
  const selectedLocation = ref({} as StoreSingle);
  const locationCoords = ref({
    lat: null,
    lng: null,
  });
  const searchLocation = ref("");
  const selectedFundingType = null as string | null;

  // Services
  const services = ref<ServiceInventoryItem[]>([]);
  const servicesMeta = ref({} as Meta);
  const selectedService = ref({});

  const searchService = ref("");
  const bookedService = ref({
    name: "",
    duration: 0,
    service_inventory_id: null,
    ndis: {
      name: "",
      value: null,
    },
    care: "comprehensive",
    type: "",
    date: [],
  });
  // services: [
  //   {
  //     id: 1,
  //     name: "Comprehensive Physiotherapy",
  //     image: {
  //       url: "https://www.perfectbalanceclinic.com/wp-content/uploads/2023/05/pexels-yan-krukau-5794059-scaled.jpg",
  //     },
  //     status: "Availabilities",
  //     description: "10 Clinical Sessions Reporting",
  //     price: 800,
  //     service_types: ["in-clinic", "virtual"],
  //     units: 10,
  //     free_times: []
  //   },
  //   {
  //     id: 2,
  //     name: "Chiropractic",
  //     image: {
  //       url: "https://doc.vortala.com/childsites/uploads/4012/files/man-doctor-chiropractor-or-osteopath-fixing-lying-womans-back-in-picture-id1291920859.jpg",
  //     },
  //     status: "Booked",
  //     description: "10 Clinical Sessions Reporting",
  //     price: 1200,
  //     units: 8,
  //     service_types: ["virtual", "mobile"],
  //     free_times: []
  //   },
  //   {
  //     id: 3,
  //     name: "Comprehensive Physiotherapy",
  //     units: 6,
  //     image: {
  //       url: "https://i0.wp.com/post.healthline.com/wp-content/uploads/2021/03/Chiropractor_Back_Male-1296x728-Header.jpg?w=1155&h=1528",
  //     },
  //     status: "Availabilities",
  //     description: "10 Clinical Sessions Reporting",
  //     price: 600,
  //     service_types: ["in-clinic", "virtual", "mobile"],
  //   },
  //   {
  //     id: 4,
  //     name: "Chiropractic",
  //     image: {
  //       url: "https://lirp.cdn-website.com/537b6562/dms3rep/multi/opt/does-health-insurance-cover-chiropractic-1920w.jpg",
  //     },
  //     status: "Booked",
  //     description: "10 Clinical Sessions Reporting",
  //     price: 1200,
  //     service_types: ["in-clinic", "mobile"],
  //     units: 15,
  //     free_times: []
  //   },
  //   {
  //     id: 5,
  //     name: "Comprehensive Physiotherapy",
  //     image: {
  //       url: "https://cdn.britannica.com/10/145410-050-44DD580D/Chiropractor-patient-spine.jpg",
  //     },
  //     status: "Availabilities",
  //     description: "10 Clinical Sessions Reporting",
  //     price: 200,
  //     units: 18,
  //     service_types: ["in-clinic", "mobile"],
  //     free_times: []
  //   },
  //   {
  //     id: 6,
  //     name: "Chiropractic",
  //     units: 6,
  //     image: {
  //       url: "https://wellchiropractic.com/wp-content/uploads/2019/10/spine-health-1080x675.jpg",
  //     },
  //     status: "Booked",
  //     description: "10 Clinical Sessions Reporting",
  //     price: 1000,
  //     service_types: ["in-clinic", "virtual", "mobile"],
  //     free_times: []
  //   },
  // ],

  // Booking Helper
  const bookingHelper = ref({} as BookingHelper);
  const bookingFundingListOfTypeLines = ref([]);
  const isBookingLoading = ref(false);

  // Send Agreement For Signing
  const agreementForSigning = ref(false);

  // Options
  const fundingLineList = ref<Option[]>([]);
  const bookingOrderTypes = ref([
    { name: "In Clinic", value: BookingServiceType.IN_CLINIC },
    { name: "Virtual", value: BookingServiceType.VIRTUAL },
    { name: "Mobile", value: BookingServiceType.MOBILE },
  ]);
  const ageRanges = ref([
    { name: "Any", value: BookingAgeRange.AGE_RANGE_ANY },
    { name: "0 - 6 years", value: BookingAgeRange.AGE_RANGE_0_6 },
    { name: "7 - 13 years", value: BookingAgeRange.AGE_RANGE_7_13 },
    { name: "14 - 17 years", value: BookingAgeRange.AGE_RANGE_14_17 },
    { name: "18+ years", value: BookingAgeRange.AGE_RANGE_18_PLUS },
  ]);
  const bookingFundingTypes = ref([]);

  // Signature Agreement
  const is_participant = ref(false);
  const agree = ref({
    media: {},
    first_name: "",
    last_name: "",
    is_agree: false,
  });
  const generatedPdfBlobUrl = ref(null);

  // Service Providers (Locations)
  async function getServiceProviders(currentParams?: any) {
    const usersStore = useUsersStore();

    const params = {
      ...currentParams,
      lat: locationCoords.value?.lat
        ? locationCoords.value?.lat
        : usersStore.userCoords?.lat,
      lng: locationCoords.value?.lng
        ? locationCoords.value?.lng
        : usersStore.userCoords?.lng,
    };

    try {
      const response = await useVaniloApi("/stores", { params });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getSingleServiceProvider(id: string, currentParams?: any) {
    const usersStore = useUsersStore();

    const params = {
      ...currentParams,
      lat: locationCoords.value?.lat
        ? locationCoords.value?.lat
        : usersStore.userCoords?.lat,
      lng: locationCoords.value?.lng
        ? locationCoords.value?.lng
        : usersStore.userCoords?.lng,
    };

    try {
      const response = await useVaniloApi(`/stores/${id}`, { params });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Services
  async function getServices(params?: any) {
    try {
      const response = await useVaniloApi("/service-inventories", { params });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  async function getBookingTimes(id: string, params: any) {
    try {
      const response = await useVaniloApi(
        `/service-inventories/${id}/booking-frames`,
        { params }
      );

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Booking Order
  async function createBookingOrder(customerId: string, body: any) {
    try {
      const response = await useVaniloApi(`/bookings/${customerId}`, {
        method: "POST",
        body,
      });

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  // DOCUMENT
  async function uploadDocument(orderId: string) {
    const formData = new FormData();

    // formData.append("media", agree.value?.media as Blob);
    // formData.append("title", agree.value?.first_name + "_sign");

    // formData.append("media", generatedPdfBlobUrl.value);
    // formData.append("title", `${orderId}-service-agreement.pdf`);

    try {
      const response = await fetch(generatedPdfBlobUrl.value);
      const pdfBlob = await response.blob();

      formData.append("media", pdfBlob, `${orderId}-service-agreement.pdf`);
      formData.append("title", `${orderId}-service-agreement.pdf`);

      const apRresponse = await useVaniloApi(`/orders/${orderId}/upload-sign`, {
        method: "POST",
        body: formData,
      });

      return apRresponse;
    } catch (error) {
      useCatchError(error);
    }
  }

  // Address
  async function getAddressName(
    latitude: number,
    longitude: number
  ): Promise<string | null> {
    try {
      const response = await useGoogleAddress(latitude, longitude);

      bookingHelper.value.store = {
        country: {
          id: response.address_components.find((component: any) =>
            component.types.includes("country")
          )?.short_name,
          name: response.address_components.find((component: any) =>
            component.types.includes("country")
          )?.long_name,
        },
        city: response.address_components.find((component: any) =>
          component.types.includes("locality")
        )?.short_name,
        postalcode: response.address_components.find((component: any) =>
          component.types.includes("postal_code")
        )?.short_name,
        province: {
          code: response.address_components.find((component: any) =>
            component.types.includes("administrative_area_level_1")
          )?.short_name,
          name: response.address_components.find((component: any) =>
            component.types.includes("administrative_area_level_1")
          )?.long_name,
        },
        address: `${
          response?.address_components?.find((component: any) =>
            component.types.includes("street_number")
          )?.short_name?.length
            ? `${
                response?.address_components?.find((component: any) =>
                  component.types.includes("street_number")
                )?.short_name
              } `
            : ""
        }${
          response?.address_components?.find(
            (component: any) =>
              component.types.includes("route") ||
              component.types.includes("establishment") ||
              component.types.includes("point_of_interest") ||
              component.types.includes("transit_station")
          )?.short_name
            ? response?.address_components?.find(
                (component: any) =>
                  component.types.includes("route") ||
                  component.types.includes("establishment") ||
                  component.types.includes("point_of_interest") ||
                  component.types.includes("transit_station")
              )?.short_name
            : ""
        }`,
        name: response?.address_components?.find(
          (component: any) =>
            component.types.includes("route") ||
            component.types.includes("establishment") ||
            component.types.includes("point_of_interest") ||
            component.types.includes("transit_station")
        )?.short_name,
        lat: latitude,
        lng: longitude,
      };

      return response;
    } catch (error) {
      useCatchError(error);
    }
  }

  return {
    isOpenSidebar,
    isEditCurrentLocation,
    locations,
    locationsMeta,
    selectedLocation,
    locationCoords,
    searchLocation,
    services,
    servicesMeta,
    selectedService,
    searchService,
    bookedService,
    bookingHelper,
    isBookingLoading,
    agreementForSigning,
    fundingLineList,
    bookingOrderTypes,
    ageRanges,
    bookingFundingTypes,
    agree,
    generatedPdfBlobUrl,
    getServiceProviders,
    getSingleServiceProvider,
    getServices,
    getBookingTimes,
    createBookingOrder,
    uploadDocument,
    getAddressName,
    selectedFundingType,
    is_participant,
    bookingFundingListOfTypeLines,
  };
});
